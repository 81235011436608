import type { Theme } from '../client';

export const NOT_APPLICABLE = 'NOT_APPLICABLE';
export const EXPIRY_DAYS = 10;
export const DEFAULT_THEME: Pick<Theme, 'colors' | 'fonts'> = {
  colors: {
    primary_text: '#212121',
    secondary_text: '#616161',
    headings: '#212121',
    foreground: '#FFFFFF',
    background: '#F5F5F5',
    accent: '#0057B2',
    button_text: '#FFFFFF',
  },
  fonts: {
    body_text: 'Roboto',
    section_title: 'Roboto',
  },
};
