import { useRef } from 'react';
import type { FieldErrors, UseFormSetFocus } from 'react-hook-form';

import type { FormValues } from '../survey';

export function useScrollToError({
  errors,
  setFocus,
}: {
  errors: FieldErrors<FormValues>;
  setFocus: UseFormSetFocus<FormValues>;
}) {
  const fieldRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const registerFieldRef = (name: string, ref: HTMLDivElement | null) => {
    fieldRefs.current[name] = ref;
  };

  const scrollToError = () => {
    const firstErrorField = Object.keys(errors)[0];
    if (firstErrorField) {
      const errorElement = fieldRefs.current[firstErrorField];
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // @ts-expect-error Type 'number' is not assignable to type 'string'.
        setFocus(firstErrorField as keyof FormValues);
      }
    }
  };

  return { registerFieldRef, scrollToError };
}
