import type { Theme } from '@mui/material';
import { theme as baseTheme } from '@trustyou/ui';
import { create } from 'zustand';

type SurveyThemeState = {
  surveyTheme: Theme;
  setSurveyTheme: (surveyTheme: Theme) => void;
};

export const useSurveyThemeStore = create<SurveyThemeState>()((set) => ({
  surveyTheme: baseTheme,
  setSurveyTheme: (surveyTheme) => set(() => ({ surveyTheme })),
}));
