import type { FieldValues, UseFormTrigger } from 'react-hook-form';

import { Box, Stack, Typography } from '@trustyou/ui';

import { CustomCardImage } from '../../components/custom-card-image';
import { SurveyPagination } from '../survey-pagination/survey-pagination';

export type SurveyHeaderProps = {
  image?: {
    url: string;
    variant: 'banner' | 'logo';
  };
  entityName?: string | null;
  pagination?: {
    initialPage?: number;
    pageCount: number;
    showProgressBar?: boolean;
  };
  trigger?: UseFormTrigger<FieldValues>;
};

export function SurveyHeader({ image, entityName, pagination, trigger }: SurveyHeaderProps) {
  return (
    <>
      {image && (
        <Box sx={{ marginInline: -5, marginBlockStart: -5 }}>
          <CustomCardImage
            image={{
              url: image.url,
              // FIXME: image.variant is not defined in some surveys on staging (check production)
              variant: image.variant ?? 'banner',
            }}
          />
        </Box>
      )}
      <Stack spacing={2} sx={{ textAlign: 'center' }}>
        {entityName && (
          <Typography component="h1" variant="h5">
            {entityName}
          </Typography>
        )}
        {pagination && pagination.pageCount > 1 && (
          <SurveyPagination {...pagination} showProgressBar trigger={trigger} />
        )}
      </Stack>
    </>
  );
}
