import { useEffect, useMemo } from 'react';

import createCache from '@emotion/cache';
import { getFontFamily, isRTL, useLanguageStore } from '@trustyou/shared';
import { createTheme, darken, theme as defaultTheme, lighten, useTheme } from '@trustyou/ui';
import deepmerge from 'deepmerge';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import type { Questionnaire } from '../../client';
import { useSurveyThemeStore } from '../../store';
import { DEFAULT_THEME } from '../../utils';

export const useSurveyTheme = (questionnaireTheme?: Questionnaire['theme']) => {
  const { colors, fonts } = questionnaireTheme ?? DEFAULT_THEME;
  const { locale } = useLanguageStore();
  const muiTheme = useTheme();
  const { surveyTheme: theme, setSurveyTheme } = useSurveyThemeStore();

  const direction = isRTL(locale) ? 'rtl' : 'ltr';
  const accent = colors.accent ?? muiTheme.palette.primary.main;

  const customTheme = createTheme(
    deepmerge(defaultTheme, {
      direction,
      palette: {
        primary: {
          main: accent,
          light: lighten(accent, 0.1),
          dark: darken(accent, 0.1),
          // TODO: Challenge the design towards using the auto-calculated contrasted text color instead of a fixed one defined in the CXP Survey Theme Editor (colors.button_text).
          // contrastText: theme.palette.getContrastText(colors.primary),
          contrastText: colors.button_text,
        },
        text: {
          primary: colors.primary_text,
          secondary: colors.secondary_text,
        },
        background: {
          default: colors.background,
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              whiteSpace: 'pre-wrap',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontFamily: getFontFamily(fonts.body_text),
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              fontFamily: getFontFamily(fonts.body_text),
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: colors.foreground,
            },
          },
        },
      },
      typography: {
        h1: {
          color: colors.headings,
          fontFamily: getFontFamily(fonts.headings),
        },
        h2: {
          color: colors.headings,
          fontFamily: getFontFamily(fonts.headings),
        },
        h3: {
          color: colors.headings,
          fontFamily: getFontFamily(fonts.body_text),
        },
        h4: {
          color: colors.headings,
          fontFamily: getFontFamily(fonts.headings),
        },
        h5: {
          color: colors.headings,
          fontFamily: getFontFamily(fonts.headings),
        },
        h6: {
          color: colors.headings,
          fontFamily: getFontFamily(fonts.headings),
        },
        subtitle1: {
          color: colors.primary_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
        subtitle2: {
          color: colors.primary_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
        body1: {
          color: colors.primary_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
        body2: {
          color: colors.primary_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
        button: {
          color: colors.button_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
        caption: {
          color: colors.secondary_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
        overline: {
          color: colors.primary_text,
          fontFamily: getFontFamily(fonts.body_text),
        },
      },
    })
  );

  const cache = useMemo(
    () =>
      createCache({
        key: direction === 'rtl' ? 'muirtl' : 'mui',
        stylisPlugins: direction === 'rtl' ? [rtlPlugin, prefixer] : [prefixer],
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [direction, theme]
  );

  useEffect(() => {
    setSurveyTheme(customTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    surveyTheme: customTheme,
    cache,
  };
};
