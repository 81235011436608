import { Fragment, useEffect, useState } from 'react';
import { type SubmitErrorHandler, type SubmitHandler, useForm } from 'react-hook-form';
import { type LoaderFunction, redirect, useLoaderData, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useLanguageStore } from '@trustyou/shared';
import {
  CustomCard,
  MAX_CARD_WIDTH_PX,
  SurveyFooter,
  SurveyHeader,
  SurveyLanguageSelector,
  SurveyPagination,
  SurveySubmitButton,
  useFeedbackPaginationStore,
} from '@trustyou/survey-manager';
import { Box, Stack, snackbar } from '@trustyou/ui';

import type {
  CreateSurveyFeedbackUseCaseIn,
  FeedbackSourceEnum,
  GetSurveyQuestionnaireUseCaseOut,
  Image,
  Language,
} from './client';
import { useScrollToError } from './hooks/use-scroll-to-error';
import { useSurvey } from './hooks/use-survey';
import { ThankYou } from './thank-you';
import type { Content } from './types';
import {
  NOT_APPLICABLE,
  findLocale,
  getLanguageOptions,
  isImage,
  isQuestion,
  isSection,
  isText,
  useValidationSchema,
} from './utils';

import { fetchQuestionnaire, submitSurvey } from './service/api';
import { useConditionalQuestions } from './service/hooks/use-conditional-questions';
import { useSurveyTheme } from './service/hooks/use-survey-theme';
import { generateValue } from './store';

export type FormValue = string | number | boolean | null;

export type FormValues = {
  [questionId: string]: FormValue | FormValue[];
};

export type SurveyData = {
  data: GetSurveyQuestionnaireUseCaseOut;
  outboundId: CreateSurveyFeedbackUseCaseIn['outbound_id'];
  source: CreateSurveyFeedbackUseCaseIn['source'];
};

const loader: LoaderFunction = async ({ request, params }) => {
  const { organizationId = '', entityId = '', surveyId = '' } = params;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const source = urlSearchParams.get('source') as FeedbackSourceEnum;
  const outboundIdFromURL = urlSearchParams.get('outbound_id');
  const outboundId = outboundIdFromURL ?? generateValue().outboundId;

  const data = await fetchQuestionnaire({
    organizationId,
    entityId,
    surveyId,
    outboundId,
    source,
  });

  if ('redirect_to' in data) {
    return redirect(data.redirect_to);
  }

  return { data, outboundId, source };
};

export default function Survey() {
  const [shouldShowThankYouPage, setShouldShowThankYouPage] = useState(false);
  const { organizationId = '', entityId = '', surveyId = '' } = useParams();
  const { data, outboundId, source } = useLoaderData() as SurveyData;
  const questionnaire = data.questionnaire;
  const entity = data.entity;
  const availableLanguages = data.available_languages;

  const { surveyTheme } = useSurveyTheme(questionnaire.theme);

  const { setLocale } = useLanguageStore();
  const [language, setLanguage] = useState(questionnaire?.default_language);

  useEffect(() => {
    setLocale(findLocale(language));
  }, [language, setLocale]);

  const handleChangeLanguage = (shortLanguageCode: Language) => {
    setLanguage(shortLanguageCode);
    setLocale(findLocale(shortLanguageCode));
  };

  const { currentPage } = useFeedbackPaginationStore();
  const pageCount = questionnaire.content?.length ?? 1;
  const hasPages = pageCount > 1;
  const isLastPage = currentPage === pageCount;
  const pageContent = questionnaire.content.find((item) => item.page === currentPage - 1);
  const submitSectionPageContent = questionnaire.submit_section?.content ?? [];

  const contentIds =
    pageContent?.content?.flatMap((item) => {
      if (isSection(item)) {
        const section = item;
        const sectionTitleId = section.id;
        const sectionItemIds = section.content?.map((sectionItem) => sectionItem.id) ?? [];
        return [sectionTitleId, ...sectionItemIds];
      }
      return item.id;
    }) ?? [];
  const submitSectionIds = submitSectionPageContent.flatMap((item) => item.id);

  const headerImage = questionnaire.content_items[
    questionnaire.theme?.header_image?.id ?? ''
  ] as Image;

  const contentItems = contentIds.map((id) => questionnaire.content_items[id]);
  const submitSectionContentItems = submitSectionIds.map((id) => questionnaire.content_items[id]);

  const questions = contentItems.filter(isQuestion);
  const submitSectionQuestions = isLastPage ? submitSectionContentItems.filter(isQuestion) : [];
  const validationSchema = useValidationSchema([...questions, ...submitSectionQuestions]);

  const shouldRenderHeader = headerImage || entity || pageCount > 1;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setFocus,
    watch,
    trigger,
    setValue,
    resetField,
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // TODO: Pass refs to children components to scroll to first errored question in current page
  const { registerFieldRef, scrollToError } = useScrollToError({ errors, setFocus });

  const { shouldRender } = useConditionalQuestions({ contentItems, watch, resetField });

  const onValid: SubmitHandler<FormValues> = async (data) => {
    const surveyFeedback = Object.entries(data).map(([questionId, value]) => ({
      question_id: questionId,
      value: value ? (value === NOT_APPLICABLE ? null : value) : null,
      ...(value === NOT_APPLICABLE && { not_applicable: true }),
    }));
    try {
      await submitSurvey({
        organizationId,
        entityId,
        surveyId,
        surveyFeedback,
        outboundId,
        source,
      });
      setShouldShowThankYouPage(true);
    } catch (error) {
      console.error('Failed to submit data.', error);
      snackbar.error('Failed to submit data.');
    }
  };

  const onInvalid: SubmitErrorHandler<FormValues> = (errors, event) => {
    scrollToError();
    console.warn({ errors, event });
  };

  const {
    renderWelcome,
    renderSectionTitle,
    renderText,
    renderImage,
    renderQuestion,
    renderSubmitSection,
  } = useSurvey({ questionnaire, language, control, errors });

  const renderContentItem = (item: Content) => {
    if (isSection(item)) return renderSectionTitle(item);
    if (isQuestion(item)) return renderQuestion({ question: item, setValue });
    if (isText(item)) return renderText(item);
    if (isImage(item)) return renderImage(item);
    console.warn(
      `Unhandled content type ${(item as Content).type} with id ${(item as Content).id}`
    );
    return null;
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        maxWidth: MAX_CARD_WIDTH_PX,
        minHeight: '100vh',
        margin: 'auto',
        paddingBlock: { xs: 2, sm: 3 },
        gap: { xs: 2, sm: 3 },
      }}
    >
      <Stack direction="row" sx={{ justifyContent: 'end', paddingInline: { xs: 2, sm: 0 } }}>
        <SurveyLanguageSelector
          languageOptions={getLanguageOptions(availableLanguages)}
          defaultLanguage={language}
          onChange={handleChangeLanguage}
        />
      </Stack>
      <Stack>
        <CustomCard>
          {shouldRenderHeader && (
            <SurveyHeader
              image={
                headerImage
                  ? {
                      url: headerImage.url as string,
                      variant: headerImage.metadata.classic_info?.type as 'banner' | 'logo',
                    }
                  : undefined
              }
              entityName={entity.name}
              pagination={shouldShowThankYouPage ? undefined : { pageCount }}
              trigger={trigger}
            />
          )}
          <Stack sx={{ paddingBlockStart: shouldRenderHeader ? 3 : 5 }}>
            {shouldShowThankYouPage ? (
              <ThankYou questionnaire={questionnaire} language={language} />
            ) : (
              <Stack
                component="form"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleSubmit(onValid, onInvalid)}
                sx={{ gap: { xs: 3, sm: 5 }, paddingBlockStart: shouldRenderHeader ? 3 : 0 }}
              >
                {renderWelcome(currentPage)}
                {contentItems.filter(shouldRender).map((item) => (
                  <Fragment key={item.id}>{renderContentItem(item)}</Fragment>
                ))}
                {isLastPage &&
                  questionnaire.submit_section &&
                  renderSubmitSection(
                    <Stack sx={{ alignItems: 'center' }}>
                      <SurveySubmitButton loading={isSubmitting} />
                    </Stack>
                  )}
                {hasPages && !isLastPage && (
                  <Stack sx={{ gap: { xs: 2, sm: 3 } }}>
                    <SurveyPagination pageCount={pageCount} trigger={trigger} />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </CustomCard>
      </Stack>
      <Stack
        sx={{
          paddingBlockStart: { xs: 4, sm: 6 },
          paddingInline: { sm: 5 },
          gap: 2,
        }}
      >
        {!shouldShowThankYouPage && <SurveyFooter entity={entity} theme={surveyTheme} />}
      </Stack>
    </Box>
  );
}

Survey.loader = loader;
