import type { FieldValues, UseFormTrigger } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Button, LinearProgress, Stack, Typography } from '@trustyou/ui';

import { submissionMessages } from '../../../../i18n-submission-ui-subset/messages';
import { useFeedbackPaginationStore } from '../../../../store/feedback-pagination-store';
import { MAX_CARD_WIDTH_PX } from '../../constants';

export type SurveyPaginationProps = {
  pageCount: number;
  trigger?: UseFormTrigger<FieldValues>;
  showProgressBar?: boolean;
};

export function SurveyPagination({
  pageCount,
  trigger,
  showProgressBar = false,
}: SurveyPaginationProps) {
  const intl = useIntl();
  const { currentPage, setCurrentPage } = useFeedbackPaginationStore();
  const progress = (currentPage / pageCount) * 100;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageCount;

  const handleValidationErrorsBeforeNavigateToPage = (page: number) => {
    const onfulfilled = (value: boolean) => {
      if (value) {
        setCurrentPage(page);
      } else {
        console.warn('onfulfilled', value);
        window.scroll({ top: 0 });
      }
    };
    const onrejected = (reason: unknown) => console.warn('onrejected', reason);
    trigger?.().then(onfulfilled, onrejected);
    window.scroll({ top: 0 });
  };

  const navigateToNextPage = () => {
    handleValidationErrorsBeforeNavigateToPage(currentPage + 1);
  };

  const navigateToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <Stack spacing={0.5} sx={{ maxWidth: MAX_CARD_WIDTH_PX }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          variant={showProgressBar || (!showProgressBar && isLastPage) ? 'text' : 'contained'}
          sx={{
            textTransform: 'uppercase',
            visibility: isFirstPage ? 'hidden' : 'visible',
          }}
          onClick={navigateToPreviousPage}
        >
          {intl.formatMessage(submissionMessages.paginationBack)}
        </Button>
        {showProgressBar && (
          <Typography variant="caption" color="text.secondary">
            {intl.formatMessage(submissionMessages.paginationPage, {
              naturalPage: currentPage,
              pageCount,
            })}
          </Typography>
        )}
        <Button
          variant={showProgressBar ? 'text' : 'contained'}
          sx={{
            textTransform: 'uppercase',
            visibility: isLastPage ? 'hidden' : 'visible',
          }}
          onClick={navigateToNextPage}
        >
          {intl.formatMessage(submissionMessages.paginationNext)}
        </Button>
      </Stack>
      {showProgressBar && (
        <LinearProgress variant="determinate" value={progress} sx={{ borderRadius: 1 }} />
      )}
    </Stack>
  );
}
