import { SurveyMessageContent } from '@trustyou/survey-manager';

import type { Questionnaire, ThankyouMessage } from './client';

export function ThankYou({
  questionnaire,
  language,
}: {
  questionnaire: Questionnaire;
  language: string;
}) {
  if (!questionnaire.thankyou_message) {
    return (
      <SurveyMessageContent
        title="[Thank you default title]"
        description="[Thank you default description]"
      />
    );
  }

  return (
    <SurveyMessageContent
      title={
        (questionnaire.content_items[questionnaire.thankyou_message.id] as ThankyouMessage).title?.[
          language
        ]
      }
      description={
        (questionnaire.content_items[questionnaire.thankyou_message.id] as ThankyouMessage)
          .description?.[language]
      }
    />
  );
}
