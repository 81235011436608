import type { ReactNode } from 'react';
import type { FieldErrors, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { iconMap } from '@trustyou/shared';
import {
  BooleanQuestion,
  type BooleanQuestionVariant,
  DateQuestion,
  type DateQuestionVariant,
  NPSQuestion,
  NumberQuestion,
  OneToFiveOptions,
  OneToTenOptions,
  ScaleQuestion,
  SelectQuestion,
  type SelectQuestionVariant,
  SurveyMessageContent,
  SurveySectionTitle,
  TextQuestion,
  type TextQuestionVariant,
  ZeroToTenOptions,
} from '@trustyou/survey-manager';
import { Stack, Typography } from '@trustyou/ui';

import type {
  BooleanPrimitive,
  Image,
  Question,
  Questionnaire,
  ScalePrimitive,
  Section,
  SelectPrimitive,
  Text,
  WelcomeMessage,
} from '../client';
import type { FormValues } from '../survey';
import {
  NOT_APPLICABLE,
  denormalizeScore,
  isMaxLength,
  isMaxValue,
  isMinLength,
  isMinValue,
  isQuestion,
  isText,
} from '../utils';

export function useSurvey({
  questionnaire,
  language,
  control,
  errors,
}: {
  questionnaire: Questionnaire;
  language: string;
  control: UseFormReturn<FormValues>['control'];
  errors: FieldErrors<FormValues>;
}) {
  const intl = useIntl();
  const defaultLanguage = questionnaire.default_language;

  function renderWelcome(currentPage: number) {
    if (currentPage !== 1 || !questionnaire.welcome_message) return null;

    const id = questionnaire.welcome_message.id;
    const welcomeMessageContent = questionnaire.content_items[id] as WelcomeMessage;
    const title =
      welcomeMessageContent.title?.[language] ?? welcomeMessageContent.title?.[defaultLanguage];
    const description =
      welcomeMessageContent.description?.[language] ??
      welcomeMessageContent.description?.[defaultLanguage];
    return <SurveyMessageContent title={title} description={description} isWelcome />;
  }

  function renderSectionTitle(item: Section) {
    const title = item.title[language] || item.title[defaultLanguage];
    if (!title) return null;
    return <SurveySectionTitle>{title}</SurveySectionTitle>;
  }

  function renderText(item: Text) {
    return (
      <SurveyMessageContent
        title={item.title?.[language] ?? item.title?.[defaultLanguage]}
        description={item.description?.[language] ?? item.description?.[defaultLanguage]}
      />
    );
  }

  function renderImage(content: Image) {
    return <img src={content.url as string} alt={content.path} style={{ borderRadius: '4px' }} />;
  }

  function renderQuestion({
    question,
    setValue,
  }: {
    question: Question;
    setValue?: UseFormSetValue<FormValues>;
  }) {
    const name = question.id ?? '';
    const isContactInfo = question.handling_type.startsWith('contact_info');
    const isMandatory = question.mandatory;
    const title = isContactInfo
      ? undefined
      : question.title[language] || question.title[question.default_language ?? ''];
    const description = isContactInfo
      ? undefined
      : question.description[language] || question.description[question.default_language ?? ''];
    const label = isContactInfo
      ? question.title[language] || question.title[question.default_language ?? '']
      : undefined;
    const variant = question.primitive.repr;
    const minLength = question.primitive.validations?.find(isMinLength)?.value;
    const maxLength = question.primitive.validations?.find(isMaxLength)?.value;
    const minValue = question.primitive.validations?.find(isMinValue)?.value;
    const maxValue = question.primitive.validations?.find(isMaxValue)?.value;
    const isSingle = maxLength === 1;
    const notApplicableCheckbox = question.use_not_applicable
      ? {
          checked: false,
          label:
            question.not_applicable_label?.[language] ??
            intl.formatMessage({
              id: 'survey.question.checkbox.not-applicable.label',
              defaultMessage: 'Not applicable',
            }),
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue?.(name, event.target.checked ? NOT_APPLICABLE : null);
          },
        }
      : undefined;
    switch (question.primitive.type) {
      case 'text': {
        return (
          <TextQuestion
            name={name}
            control={control}
            errors={errors}
            variant={variant as TextQuestionVariant}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            minCharacters={minLength}
            maxCharacters={maxLength}
            label={label}
            showMandatoryAsterisk={isMandatory}
            showMandatoryAsteriskForLabel={isMandatory}
            isContactInfo={isContactInfo}
          />
        );
      }
      case 'select': {
        const variantMap: Record<string, SelectQuestionVariant> = {
          dropdown: isSingle ? 'dropdown_single' : 'dropdown_multiple',
        };
        return (
          <SelectQuestion
            name={name}
            control={control}
            errors={errors}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            variant={variantMap[variant as SelectQuestionVariant] ?? variant}
            options={(question.primitive as SelectPrimitive).options.map((option) => ({
              value: option.value,
              label: option.label[language] ?? option.label[defaultLanguage],
              icon: iconMap[option.icon ?? ''],
              color: option.color,
            }))}
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      case 'scale': {
        const isOverallScore = question.handling_type === 'overall_score';
        const urlSearchParams = new URLSearchParams(window.location.search);
        const overallScore = parseInt(String(urlSearchParams.get('overall_score')));
        const initialValue =
          isOverallScore && overallScore
            ? denormalizeScore(overallScore, Number(maxValue))
            : undefined;

        return (
          <ScaleQuestion
            name={name}
            initialValue={initialValue}
            control={control}
            errors={errors}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            options={
              (question.primitive as ScalePrimitive).options?.map((option) => ({
                value: option.value,
                label: option.label[language] ?? option.label[defaultLanguage],
                icon: iconMap[option.icon ?? ''],
                color:
                  (option.color ?? maxValue === 5)
                    ? OneToFiveOptions.find((o) => o.value === option.value)?.color
                    : OneToTenOptions.find((o) => o.value === option.value)?.color,
              })) ?? []
            }
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      case 'nps': {
        return (
          <NPSQuestion
            name={name}
            control={control}
            errors={errors}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            options={ZeroToTenOptions.map((defaultOption) => {
              const npsOptions = (question.primitive as ScalePrimitive).options ?? [];
              const npsOption = npsOptions.find((o) => o.value === defaultOption.value) || null;
              const label =
                (defaultOption.value === 0
                  ? (npsOptions.find((o) => o.value === 1)?.label?.[language] ??
                    npsOptions.find((o) => o.value === 1)?.label?.[defaultLanguage])
                  : (npsOption?.label?.[language] ?? npsOption?.label?.[defaultLanguage])) ??
                defaultOption.label;

              return {
                value: defaultOption.value,
                label: label,
                icon: iconMap[npsOption?.icon ?? ''] || defaultOption.icon,
                color: npsOption?.color ?? defaultOption.color,
              };
            })}
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      case 'number': {
        return (
          <NumberQuestion
            name={name}
            control={control}
            errors={errors}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            min={minValue as number}
            max={maxValue as number}
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      case 'boolean': {
        if (variant === 'checkbox') {
          const isLegalCheckbox = ['data_privacy_information', 'terms_and_conditions'].includes(
            question.handling_type
          );
          if (isLegalCheckbox) {
            return (
              <BooleanQuestion
                name={name}
                control={control}
                errors={errors}
                variant={variant}
                isLegalCheckbox={isLegalCheckbox}
                options={[
                  {
                    value: 'accept',
                    label: question.title[language] ?? question.title[defaultLanguage],
                  },
                ]}
                showMandatoryAsterisk={isMandatory}
              />
            );
          }
          return (
            <BooleanQuestion
              name={name}
              control={control}
              errors={errors}
              title={title}
              description={description}
              notApplicableCheckbox={notApplicableCheckbox}
              variant={variant}
              options={[
                {
                  label: question.title[language] ?? question.title[defaultLanguage],
                  value: question.title[language] ?? question.title[defaultLanguage],
                },
              ]}
              showMandatoryAsterisk={isMandatory}
            />
          );
        }
        // Default BooleanQuestion that renders Yes/No options with some colored icons.
        return (
          <BooleanQuestion
            name={name}
            control={control}
            errors={errors}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            variant={variant as BooleanQuestionVariant}
            options={
              (question.primitive as BooleanPrimitive).options?.map((option) => ({
                value: option.value,
                label: option.label[language] ?? option.label[defaultLanguage],
                icon: iconMap[option.icon ?? ''],
                color: option.color,
              })) ?? []
            }
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      case 'date': {
        const variantMap: Record<string, DateQuestionVariant> = {
          datepicker: 'day-month-year',
          year_month_picker: 'month-year',
        };
        return (
          <DateQuestion
            name={name}
            control={control}
            errors={errors}
            title={title}
            description={description}
            notApplicableCheckbox={notApplicableCheckbox}
            variant={variantMap[variant as DateQuestionVariant] ?? variant}
            showMandatoryAsterisk={isMandatory}
          />
        );
      }
      default:
        return (
          <Typography sx={{ color: (theme) => theme.palette.warning.dark }}>
            Unhandled primitive type <b>{question.primitive.type}</b> with id <b>{question.id}</b>
          </Typography>
        );
    }
  }

  function renderSubmitSection(children?: ReactNode) {
    if (!questionnaire.submit_section) return null;
    const submitSectionItems = questionnaire.submit_section.content.map(
      (item) => questionnaire.content_items[item.id]
    );
    const submitSectionQuestions = submitSectionItems.filter(isQuestion);
    const submitSectionTexts = submitSectionItems.filter(isText);
    const nameQuestion = submitSectionQuestions.find(
      (item) => item.handling_type === 'contact_info_name'
    );
    const roomQuestion = submitSectionQuestions.find(
      (item) => item.handling_type === 'contact_info_room_number'
    );
    const emailQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'contact_info_email'
    );
    const phoneQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'contact_info_phone'
    );
    const privacyQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'data_privacy_information'
    );
    const termsQuestion = submitSectionQuestions.find(
      (question) => question.handling_type === 'terms_and_conditions'
    );
    const termsAndPrivacyText = submitSectionTexts.at(0) as Text;
    return (
      <>
        {renderSectionTitle(
          questionnaire.content_items[questionnaire.submit_section.id] as Section
        )}
        <Stack spacing={3}>
          {nameQuestion && renderQuestion({ question: nameQuestion })}
          {roomQuestion && renderQuestion({ question: roomQuestion })}
          {emailQuestion && renderQuestion({ question: emailQuestion })}
          {phoneQuestion && renderQuestion({ question: phoneQuestion })}
          <Stack>
            {privacyQuestion && renderQuestion({ question: privacyQuestion })}
            {termsQuestion && renderQuestion({ question: termsQuestion })}
            {/* {pushToProviderQuestion && renderQuestion(pushToProviderQuestion)} */}
            {termsAndPrivacyText && (
              <SurveyMessageContent
                title={
                  termsAndPrivacyText.title?.[language] ??
                  termsAndPrivacyText.title?.[defaultLanguage]
                }
                description={
                  termsAndPrivacyText.description?.[language] ??
                  termsAndPrivacyText.description?.[defaultLanguage]
                }
              />
            )}
          </Stack>
        </Stack>
        {children}
      </>
    );
  }

  return {
    renderWelcome,
    renderSectionTitle,
    renderText,
    renderImage,
    renderQuestion,
    renderSubmitSection,
  };
}
